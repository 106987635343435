import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import api from './api'
import base from './api/base'

Vue.config.productionTip = false

Vue.prototype.$api = api

if(process.env.NODE_ENV == "development"){
  base.url = '/api'
}
if(process.env.NODE_ENV == "test"){
  base.url = 'http://api.tbay.com/api'
}
if(process.env.NODE_ENV == "production"){
  base.url = 'https://api.tbay.store/api'
}

router.afterEach((to, from) => { // eslint-disable-line no-unused-vars
  ga('set', 'page', to.path) // eslint-disable-line no-unused-vars
  ga('send', 'pageview') // eslint-disable-line no-unused-vars
  gtag('config', 'G-EH0MB7XKBM', { // eslint-disable-line no-unused-vars
    page_title: to.name, // eslint-disable-line no-unused-vars
    page_path: to.path, // eslint-disable-line no-unused-vars
  }) // eslint-disable-line no-unused-vars
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
